<template>
  <div class="phone-confirmation-page" v-thematic>
    <nav v-if="!show_region_list">
      <svg
        v-if="!isDarkMode"
        @click="goBack()"
        width="25"
        height="26"
        viewBox="0 0 25 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.488 25.3541C16.956 25.0669 17.1044 24.4517 16.8196 23.9799L10.1892 13L16.8196 2.02007C17.1044 1.54832 16.956 0.933102 16.488 0.645945C16.02 0.358789 15.4096 0.508438 15.1248 0.980192L8.18043 12.4801C7.98757 12.7994 7.98757 13.2006 8.18043 13.5199L15.1248 25.0198C15.4096 25.4916 16.02 25.6412 16.488 25.3541Z"
          fill="#1d1d1d"
        />
      </svg>
      <svg
        v-else
        @click="goBack()"
        width="25"
        height="26"
        viewBox="0 0 25 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.488 25.3541C16.956 25.0669 17.1044 24.4517 16.8196 23.9799L10.1892 13L16.8196 2.02007C17.1044 1.54832 16.956 0.933102 16.488 0.645945C16.02 0.358789 15.4096 0.508438 15.1248 0.980192L8.18043 12.4801C7.98757 12.7994 7.98757 13.2006 8.18043 13.5199L15.1248 25.0198C15.4096 25.4916 16.02 25.6412 16.488 25.3541Z"
          fill="#ededed"
        />
      </svg>
    </nav>
    <div v-if="step == 1">
      <h4 v-thematic class="phone-num-title">
        {{ $t("phone_confirm.title") }}
      </h4>
      <p v-thematic class="phone-num-sub">{{ $t("phone_confirm.sub") }}</p>

      <div class="phone-confirm-field" v-if="ask_name">
        <input
          type="text"
          :placeholder="$t('roulette.name_ph')"
          class="name-input"
          v-thematic
          ref="phoneInput"
          v-model="user_name"
        />
      </div>

      <div class="phone-confirm-field" v-if="country_region">
        <span v-thematic class="code-region" @click="openRegionList">
          <small>({{ country_region.country_code }})</small>
          {{ country_region.dialling_code }}
        </span>
        <input
          type="text"
          :placeholder="country_region.placeholder"
          class="phone"
          pattern="[0-9-+]*"
          v-thematic
          inputmode="numeric"
          ref="phoneInput"
          @accept="onPhoneMaskAccept"
          @complete="onPhoneMaskComplete"
          v-imask="phone_mask"
        />
      </div>
      <a
        href="#"
        :class="{
          active: phone && (ask_name ? user_name : true),
        }"
        class="next"
        @click.prevent="onNext"
        >{{ $t("cart.next") }}</a
      >
    </div>

    <CodeRegionList
      ref="regionList"
      :initial_phone="initial_phone"
      @close="show_region_list = false"
      @select="(r) => (country_region = r)"
      v-show="show_region_list"
    />

    <div v-if="step == 2">
      <h4 class="phone-num-title">{{ $t("phone_confirm.smscode") }}</h4>
      <p class="phone-num-sub">{{ $t("phone_confirm.sub") }}</p>
      <input
        type="text"
        maxlength="4"
        v-thematic
        v-model="sms_code"
        placeholder="X-X-X-X"
        pattern="[0-9-+]*"
        ref="smsCodeInput"
        inputmode="numeric"
        class="sms-code"
      />
      <a
        href="#"
        :class="sms_code && sms_code.length >= 4 ? 'active' : ''"
        class="next"
        @click.prevent="sendSMSCode"
        >{{ $t("cart.next") }}</a
      >
    </div>
  </div>
</template>

<script>
import ClientPhoneField from "@/components/order-details/ClientPhoneField.vue";
import CodeRegionList from "@/components/modals/CodeRegionList.vue";

import { IMaskDirective } from "vue-imask";

import SecurityAPI from "@/api/security";

export default {
  data() {
    return {
      step: 1,
      phone: null,
      country_region: null,

      sms_code: null,

      user_name: null,

      show_region_list: false,

      confirming_token: null,
    };
  },
  props: {
    initial_phone: String,
    ask_name: Boolean,
    verify_sms: {
      type: Boolean,
      default: false
    },
    cafe_id: String
  },
  methods: {
    goBack() {
      if (this.step == 1) {
        this.$emit("close");
      } else if (this.step == 2) {
        this.step = 1;
      }
    },
    onPhoneChange(ev) {
      console.log(ev);
    },
    checkInitialPhone() {
      if (this.initial_phone) {
        this.phone = this.initial_phone;
      } else {
        return;
      }

      this.$nextTick(() => {
        const without_dial_code = this.phone.substring(
          this.country_region.dialling_code.length
        );
        this.$refs.phoneInput.value = without_dial_code;
        this.$refs.phoneInput.maskRef.updateValue();
      });
    },
    askPhoneConfirmation() {
      SecurityAPI.ask(this.rawPhoneValue, this.cafe_id)
        .then((data) => {
          this.confirming_token = data.token;
          this.step = 2;

          this.$nextTick(() => {
            setTimeout(() => {
              this.$refs.smsCodeInput.focus();
            }, 100);
          });
        })
        .catch((error) => {
          //if cafe cannot afford sending 1 sms, we skip the verification
          if (error == 'cafe_not_paid') {
            this.$emit("confirm", this.rawPhoneValue);
            this.$emit('close');
            return;
          }

          alert(
            `Сталася помилка підтвердження. Будь ласка, спробуйте пізніше.`
          );
        });
    },
    openRegionList() {
      this.show_region_list = true;
      this.$refs.regionList.checkInitialPhone();
    },
    onPhoneMaskAccept() {
      this.phone = null;
    },
    onPhoneMaskComplete(ev) {
      const detail = ev.detail;
      this.phone = detail.unmaskedValue;
    },
    onNext() {
      if (this.step == 1) {
        if (this.phone) {
          if (this.phone == this.initial_phone) {
            this.$emit("close");
            return;
          }
          if (this.ask_name && !this.user_name) return;

          if (!this.verify_sms) {
            this.$emit("confirm", this.rawPhoneValue);
            if (this.ask_name) {
              this.$emit('name', this.user_name);
            }
            this.$emit("close");
            return;
          }

          this.askPhoneConfirmation();
        }
      }
    },
    sendSMSCode() {
      if (!this.sms_code || this.sms_code.length != 4) return;

      SecurityAPI.check(this.confirming_token, this.sms_code)
        .then((data) => {
          if (data.result) {
            this.$emit("confirm", this.rawPhoneValue);
            if (this.ask_name) {
              this.$emit('name', this.user_name);
            }
            this.$emit("close");
          } else {
            this.sms_code = null;
          }
        })
        .catch((error) => {
          this.sms_code = null;
        });
    },
  },
  mounted() {
    this.checkInitialPhone();
    this.toggleGlobalScrolling(true);
   
    setTimeout(() => {
      console.log(this.$refs.phoneInput.maskRef);
      this.$refs.phoneInput.maskRef.updateValue();
      this.$refs.phoneInput.maskRef.updateControl();
      this.$refs.phoneInput.addEventListener("change", this.onPhoneChange);
      this.$refs.phoneInput.focus();
      this.$refs.phoneInput.maskRef.alignCursorFriendly();
    }, 100);
  },
  watch: {
    show_region_list() {
      this.toggleGlobalScrolling(!this.show_region_list);
    },
  },
  beforeDestroy() {
     this.toggleGlobalScrolling(false);
  },
  computed: {
    phone_mask() {
      return {
        mask: this.country_region.mask,
        lazy: false,
      };
    },
    rawPhoneValue() {
      if (!this.phone) return "";

      return (this.country_region.dialling_code + this.phone)
        .replace(/\s-()_/g, "")
        .trim();
    },
  },
  components: {
    ClientPhoneField,
    CodeRegionList,
  },
  directives: {
    imask: IMaskDirective,
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

.phone-confirmation-page {
  height: 100vh;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  text-align: center;
  width: 100%;
  background: #ededed;
  z-index: 10;
}

.phone-confirmation-page.dark {
  background: #1d1d1d;
}

.phone-num-title {
  font-size: 2.3vh;
}

.phone-num-title.dark {
  color: #ededed;
}

.phone-num-sub {
  font-size: 1.7vh;
  color: #464646;
}

.phone-num-sub.dark {
  color: #908f8d;
}

nav {
  padding: 2.2vh 2.5vh;
  margin-right: auto;
  height: 7.7vh;
}

nav svg {
  display: block;
  margin-left: -0.8vh;
  height: 3.2vh;
  width: auto;
}

.phone-confirm-field {
  width: fit-content;
  margin: 0 auto;
  padding-top: 5.5vh;
  display: flex;
}

.code-region {
  line-height: 1.5;
  padding-right: 1.2vh;
  color: #1d1d1b;
  font-size: 2.3vh;
}

.code-region.dark {
  color: #ededed;
}

input.phone,
input.sms-code,
input.name-input {
  -webkit-appearance: none;
  margin: 0;
  display: block;
  margin: 0 auto;
  width: 14.5vh;
  background: transparent;
  font-size: 2.3vh;
  border: none;
  border-radius: 0;
  color: #1d1d1d;
  border-bottom: 1px solid #464646;
  outline: none;
}

.name-input {
  width: 26vh !important;
  text-align: center;
}

input.phone.dark,
input.sms-code.dark,
input.name-input.dark {
  color: #ededed;
}

input.sms-code {
  width: 9vh;
  padding-top: 5.5vh;
  text-align: center;
}

a.next {
  color: #908f8d;
  font-size: 2.3vh;
  position: absolute;
  bottom: 52vh;
  left: 0;
  right: 0;
  pointer-events: none;
}

a.next.active {
  pointer-events: all;
  color: #129b81;
}
</style>