var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-detail",on:{"click":function($event){return _vm.$emit('click')}}},[_c('img',{ref:"iconImage",attrs:{"src":_vm.currentIcon}}),(!_vm.value)?_c('input',{directives:[{name:"imask",rawName:"v-imask",value:(_vm.phone_mask),expression:"phone_mask"}],ref:"phoneInput",class:{
      'detail-input': true,
      dark: _vm.isDarkMode,
      'detail-placeholder': _vm.dark_placeholder,
      'detail-red-input': !_vm.valid,
    },attrs:{"type":"text","pattern":"[0-9-+]*","inputmode":"numeric","placeholder":_vm.$t('reserve.phone_ph'),"readonly":""},on:{"accept":_vm.onPhoneMaskAccept,"complete":_vm.onPhoneMaskComplete}}):_c('input',{class:{
      'detail-input': true,
      dark: _vm.isDarkMode,
      'detail-red-input': !_vm.valid,
    },attrs:{"type":"text","pattern":"[0-9-+]*","inputmode":"numeric","readonly":"","placeholder":_vm.$t('reserve.phone_ph')},domProps:{"value":_vm.value}}),_c('div',{staticClass:"selective-item"},[_c('img',{class:!_vm.value ? 'arrow disabled' : 'arrow',attrs:{"src":_vm.isDarkMode ? '/icons/arrow-black.svg' : '/icons/arrow-white.svg'}})])])}
var staticRenderFns = []

export { render, staticRenderFns }