<template>
  <div class="fondy-wrapper">
    <h2 class="fondy-popup-info">
      <img src="/icons/fondy_dark.png" class="fondy-icon" />

      <a :href="widget.checkout_url" @click="$emit('close')" target="_blank">
      <!-- <form
        method="POST"
        action="https://pay.fondy.eu/api/checkout/redirect/"
        accept-charset="utf-8"
      >
        <input
          type="hidden"
          v-for="(v, k) in widget"
          :key="k"
          :name="k"
          :value="v"
        /> -->

        <LongButton variant="success" type="submit" class="desktop-button">
          {{ $t("online_payment_modal.redirect_btn") }}
        </LongButton>
        </a>
      <!-- </form> -->
      <LongButton
        variant="danger"
        class="desktop-button"
        @click="$emit('close')"
      >
        {{ $t("online_payment_modal.cancel_btn") }}
      </LongButton>
    </h2>
  </div>
</template>

<script>
import LongButton from "@/components/LongButton.vue";

export default {
  props: {
    widget: Object,
  },
  mounted() {
    // document.getElementById('payuRedirectLink').click();
    // this.$emit('close');
  },
  components: {
    LongButton,
  },
};
</script>

<style scoped>
.fondy-wrapper {
  /* background: #908f8d; */
  opacity: 1;
  padding-top: 35vh;
}

.fondy-popup-info {
  color: #ededed;
  font-size: 2vh;
  opacity: 1;
  text-align: center;
}

.fondy-icon {
  width: 50vw;
}

@media screen and (min-width: 768px) {
  .fondy-wrapper {
    padding-top: 25vh;
  }

  .fondy-icon {
    width: 25vh;
  }

  .desktop-button {
    width: 45vh;
    font-size: 2.2vh;
  }
}
</style>