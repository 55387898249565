<template>
  <div>
    <div class="hide-content"></div>
    <div class="container-modal">
      <div class="modal-box">
        <div class="title">
          <h3 :style="{ color: modeBgColor }">{{ titleMode }}</h3>
        </div>
        <div class="pic-wrapper">
          <img :src="modePicture" class="mode-pic" v-if="canShowPanda"/>

          <svg
            width="48"
            height="16"
            viewBox="0 0 48 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            v-if="canShowPanda"
          >
            <path
              d="M36.3894 8.77103H1.04298C0.49069 8.77103 0.0388184 8.31915 0.0388184 7.76686C0.0388184 7.21457 0.49069 6.7627 1.04298 6.7627H36.3894C36.9417 6.7627 37.3936 7.21457 37.3936 7.76686C37.3936 8.31915 36.9417 8.77103 36.3894 8.77103Z"
              :fill="modeBgColor"
            />
            <path
              d="M45.5234 15.2979H10.177C9.62472 15.2979 9.17285 14.846 9.17285 14.2937C9.17285 13.7414 9.62472 13.2896 10.177 13.2896H45.5234C46.0757 13.2896 46.5276 13.7414 46.5276 14.2937C46.5276 14.846 46.0757 15.2979 45.5234 15.2979Z"
              :fill="modeBgColor"
            />
            <path
              d="M46.1778 2.8965H24.5884C24.0361 2.8965 23.5842 2.44463 23.5842 1.89234C23.5842 1.34005 24.0361 0.888184 24.5884 0.888184H46.1778C46.7301 0.888184 47.182 1.34005 47.182 1.89234C47.182 2.44463 46.7301 2.8965 46.1778 2.8965Z"
              :fill="modeBgColor"
            />
          </svg>
        </div>
        <div
          class="option-info color-black"
          :style="{ backgroundColor: modeBgColor }"
        >
          <div class="order-mode-info" v-if="showBuiltinTitle">
            <p class="order-description">
              {{ $t(`mode_modal.${titleMode.toLowerCase()}_title`) }}
            </p>
          </div>
          <!-- <div class="order-mode-info">
            <h4 class="order-header"  v-if="deliveryState">
              {{ $t('mode_modal.delivery_price_header') }}
            </h4>
            <p class="order-description" v-if="deliveryState">
              {{ $t(`mode_modal.delivery_types.${deliveryState.type}`, {
                cost: deliveryState.cost,
                currency: currency.symbol
              }) }}
            </p>
            <p class="order-description" v-if="deliveryState && deliveryState.min_check">
              {{ $t(`mode_modal.delivery_types.conditional`, {
                min_check: deliveryState.min_check,
                currency: currency.symbol
              }) }}
            </p>
            </div>

            <div class="order-mode-info">
            <h4 class="order-header" v-if="hasMinCheck">
              {{ $t("mode_modal.min_check_header") }}
            </h4>
            <p class="order-description" v-if="hasMinCheck">
              {{ $t("mode_modal.min_check_content") }} {{ minCheckCost || 0
              }}{{ currency.symbol }}
            </p>
            </div> -->

          <!-- <slot name="info"></slot> -->
          <div class="motd" v-if="motd" v-html="motdHtml"></div>
        </div>
      </div>
    </div>

    <div class="shadow-bg">
      <LongButton class="ok" @click="emitClose">
        {{ buttonText || $t("mode_modal.btn_menu") }}
      </LongButton>
      <!-- <LongButton class="hide-info" @click="dontShowAgain">
        {{ $t('mode_modal.btn_hide') }}
      </LongButton> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    titleMode: String,
    orderInfo: String,
    modeBgColor: String,
    modePicture: String,

    deliveryState: Object,

    motd: String,
    cafe: Object,
    buttonText: String,

    showBuiltinTitle: {
      type: Boolean,
      default: true
    },

    minCheckCost: {
      type: Number,
      default: null,
    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    dontShowAgain() {
      window.localStorage.setItem(
        `order_modal_hidden:${this.cafe._id}`,
        `${Date.now()}`
      );

      this.emitClose();
    },
  },
  computed: {
    hasMinCheck() {
      return this.minCheckCost !== null;
    },
    currency() {
      return this.$store.state.currency.current;
    },
    motdHtml() {
      //TODO: add html sanitizing here
      if (!this.motd) return "";

      return this.motd.replace(/\n/g, "<br>");
    },
    canShowPanda() {
      return this.cafe ? !this.cafe.ui_settings.hide_panda : true;
    }
  },
  mounted() {
    // this.toggleGlobalScrolling(true);
    // const was_hidden = window.localStorage.getItem(`order_modal_hidden:${this.cafe._id}`);
    // if (was_hidden) this.emitClose();
    if (!this.motd) {
      this.emitClose();
    }
  },
  beforeDestory() {
    // this.toggleGlobalScrolling(false);
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

.ok {
  position: absolute;
  bottom: 2.2vh;
  left: 0;
  right: 0;
  box-shadow: none;
  width: 88%;
  background: #1d1d1d;
  color: #ededed;
  border-radius: 1vh;
}

.hide-info {
  position: absolute;
  bottom: 3vh;
  left: 0;
  right: 0;
  box-shadow: none;
  width: 88%;
  background: transparent;
  color: #1d1d1b;
  border: 1px solid #1d1d1b;
  border-radius: 1vh;
}

.shadow-bg {
  background: rgba(255, 255, 255, 0.01);
  backdrop-filter: blur(10px);
  position: fixed;
  width: 100%;
  z-index: 10;
  bottom: 0;
  height: 11vh;
}

.hide-content {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
  z-index: 9;
  top: 0;
  left: 0;
}

.info {
  position: relative;
  bottom: 0;
}

@keyframes slideup {
  0% {
    opacity: 0;
    transform: translateY(200%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.container-modal {
  animation: slideup 0.5s forwards;
  position: absolute;
  background: transparent;
  z-index: 10;
  top: 0;

  overflow: auto;
  width: 100%;
  left: 0;
  margin: 0 auto;
  right: 0;
}

h4.order-header {
  color: #1d1d1d;
  font-size: 2.5vh;
  padding-bottom: 0.7vh;
}

h3 {
  font-size: 7.5vw;
  line-height: 0.8;
}

.modal-box {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  min-height: inherit;
  padding-top: 2vh;
  border-radius: 2vh 2vh 0 0;
  margin: 8vh auto 0;
  overflow: hidden;
  background-color: #1d1d1b;
  width: 100%;
  position: relative;
}

img.mode-pic {
  height: 20vh;
  width: fit-content;
  display: block;
  margin-left: auto;
}

.pic-wrapper {
  position: relative;
  height: 20vh;
  width: 70%;
  margin-left: auto;
  top: 0;
}

svg {
  position: absolute;
  left: 15%;
  bottom: 15%;
}

.title {
  position: absolute;
  top: 3.3vh;
  left: 3vh;
}

.option-info {
  position: relative;
  width: 100%;
  overflow: hidden;
  bottom: 0;
  padding: 3.7vh 5.4vw 15vh;
  height: auto;
  min-height: 80vh;
}

p.order-description {
  color: #1d1d1b;
  font-size: 2.2vh;
}

.option-info p {
  line-height: 1.2;
}

.motd {
  margin-top: 1.8vh;
}

@media screen and (min-width: 768px) {
  .modal-box {
    min-height: 50vh;
    width: 61vh;
  }
  
  .option-info p {
    font-size: 2.2vh;
    color: #1d1d1b;
  }
  .option-info {
    padding: 2.2vh 5.4vh;
  }
  h3 {
    font-size: 4vh;
  }

  .pt-20 {
    padding-top: 1.7vh;
  }

  .title {
    padding: 0.8vh 5.4vh;
  }

  .ok,
  .hide-info {
    width: 60vh;
  }
}
</style>
