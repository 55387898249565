<template>
  <div class="order-detail" @click="$emit('click')">
    <img :src="currentIcon" ref="iconImage" />
    <input
      type="text"
      :class="{
        'detail-input': true,
        dark: isDarkMode,
        'detail-placeholder': dark_placeholder,
        'detail-red-input': !valid,
      }"
      ref="phoneInput"
      pattern="[0-9-+]*"
      inputmode="numeric"
      @accept="onPhoneMaskAccept"
      :placeholder="$t('reserve.phone_ph')"
      v-imask="phone_mask"
      @complete="onPhoneMaskComplete"
      readonly
      v-if="!value"
    />
    <input
      type="text"
      :class="{
        'detail-input': true,
        dark: isDarkMode,
        'detail-red-input': !valid,
      }"
      pattern="[0-9-+]*"
      inputmode="numeric"
      readonly
      :value="value"
      :placeholder="$t('reserve.phone_ph')"
      v-else
    />
    <div class="selective-item">
      <img
        :src="isDarkMode ? '/icons/arrow-black.svg' : '/icons/arrow-white.svg'"
        :class="!value ? 'arrow disabled' : 'arrow'"
      />
    </div>
  </div>
</template>

<script>
import { IMaskDirective } from "vue-imask";
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    valid: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isPhoneComplete: false,
      dark_placeholder: false,
    };
  },
  watch: {
    valid() {
      if (!this.valid) this.toggleIconClass(true);
    },
    value() {
      if (this.value) {
        this.$refs.phoneInput.value = this.value;
        this.dark_placeholder = false;
        this.toggleIconClass(true);
        this.$refs.phoneInput.classList.remove("detail-placeholder");
      }
    },
  },
  methods: {
    onPhoneMaskComplete(ev) {
      const detail = ev.detail;
      this.isPhoneComplete = true;
      this.$emit("input", detail.unmaskedValue);
      this.toggleIconClass(true);
    },
    onPhoneMaskAccept(ev) {
      this.toggleIconClass(false);

      if (ev.detail.unmaskedValue.length < 4) {
        this.$refs.phoneInput.classList.add("detail-placeholder");
      } else {
        this.$refs.phoneInput.classList.remove("detail-placeholder");
      }

      this.$emit("clear");
    },
    toggleIconClass(enable) {
      if (!enable) {
        this.$refs.iconImage.classList.add("disabled");
      } else {
        this.$refs.iconImage.classList.remove("disabled");
      }
    },
  },
  mounted() {
    this.toggleIconClass(!!this.value);

    this.$nextTick(() => {
      if (this.value) {
        this.$refs.phoneInput.value = this.value;
        this.dark_placeholder = false;
        this.$refs.phoneInput.classList.remove("detail-placeholder");
      }

      if (!this.value)
        this.$refs.phoneInput.classList.add("detail-placeholder");
    });
  },
  computed: {
    phone_mask() {
      return {
        mask: this.$store.getters["worldwide/phoneMask"],
        lazy: false,
      };
    },
    currentIcon() {
      const themeSuffix = this.isDarkMode ? "-white" : "";
      if (!this.valid) return `/icons/details/phone-red.svg`;
      if (this.value === null) return `/icons/details/phone${themeSuffix}.svg`;
      return `/icons/details/phone${themeSuffix}.svg`;
    },
  },
  directives: {
    imask: IMaskDirective,
  },
};
</script>

<style scoped>
.detail-placeholder {
  color: #a4a3a1;
}

.order-detail img {
  /* margin-right: 2.2vw !important; */
}

.detail-red-input {
  color: #e2223b !important;
}

.detail-input:disabled {
  color: #1d1d1b !important;
}
</style>