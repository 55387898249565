<template>
  <div class="pay-total-box">
    <!-- <h5>{{ $t('pay_methods.title') }}</h5> -->

    <div class="pay-method" v-thematic="!ignoreTheme">
      <select :value="value" @input="onUpdate" v-show="!only_one_method">
        <option :value="null" disabled>
          {{ $t("pay_methods.null.long") }}
        </option>
        <option :value="'cash'" v-if="available_methods.cash">
          {{ $t("pay_methods.cash.long") }}
        </option>
        <option :value="'card'" v-if="available_methods.card">
          {{ $t("pay_methods.card.long") }}
        </option>
        <option :value="'online'" v-if="available_methods.online">
          {{ $t("pay_methods.online.long") }}
        </option>
        <option :value="'bonus'" v-if="showBonus">
          {{ $t("pay_methods.bonus.long") }}
        </option>
      </select>

      <img :src="currentIcon" :class="!value && validation ? 'disabled' : ''" />
      <!-- card \ online -->

      <p
        :class="{
          'align-self-center': true,
          'invalid-input': !validation,
          unselected: value === null,
        }"
        :style="{
          color: !validation ? '#e2223b !important' : '',
        }"
      >
        {{ $t(`pay_methods.${value}.long`) }}
      </p>

      <div class="selective-item" v-show="!only_one_method">
        <img
          :src="
            isDarkMode && !ignoreTheme ? '/icons/arrow-black.svg' : '/icons/arrow-white.svg'
          "
          :class="value == null ? 'arrow disabled' : 'arrow'"
        />
      </div>
    </div>
    <div class="bonus-balance" v-if="bonus_balance != null && isAuthorized">
      <span class="bonus-text">{{ $t("pay_methods.bonus_balance") }}</span>
      <span
        :class="{
          'bonus-value': true,
          'bonus-value-zero': bonus_balance == 0,
        }"
        v-thematic="!ignoreTheme"
        >{{ bonus_balance }}{{ currency.symbol }}</span
      >
    </div>
    <!-- <h4 class="total-price-box" v-thematic>
      <span>{{ $t('pay_methods.total') }}</span>
      <span class="value">{{ total }} {{currency.code}}</span>
    </h4> -->
  </div>
</template>

<style scoped>
.pay-total-box {
  padding: 0;
  color: #1d1d1b;
}

.pay-total-box h5 {
  color: #a4a3a1;
  /* font-size: 1.7vh; */
  font-size: 2vh;
}

.invalid-input {
  color: #e2223b !important;
}

.pay-method.dark > * {
  color: #ededed !important;
}

.pay-method {
  position: relative;
  display: flex;
  color: #1d1d1b;
  padding: 2vh 0 2vh 0;
  width: 100%;
  border-bottom: 1px solid #a4a3a1;
}

.pay-method > * {
  color: #1d1d1b;
}

.pay-method p {
  margin: 0;
  font-size: 2.2vh;
  width: inherit;
  color: #1d1d1b;
}

p.align-self-center.unselected {
  color: #a4a3a1 !important;
}

.pay-method img {
  height: auto;
  width: 6.8vw;
  margin-right: 3.7vw;
}

img.arrow {
  margin: 0;
  transform: rotate(-90deg);
}

img.disabled {
  opacity: 0.45;
}

.selective-item {
  width: auto;
}
.selective-item img {
  float: right;
  margin: 1vh -0.5vh 0 0;
  height: 1.5vh;
}

select:focus ~ .selective-item img {
  transform: rotate(-180deg);
  transition: 0.3s;
}

select {
  width: 95%;
  position: absolute;
  background: transparent;
  border: none;
  opacity: 0;
}

.bonus-balance {
  display: flex;
  justify-content: space-between;
  font-size: 2vh;
  color: #908f8d;
  align-items: baseline;
  padding: 1.2vh 0 1.2vh;
  border-bottom: 1px solid #a4a3a1;
}

span.bonus-value {
  float: right;
  color: #129b81;
  font-size: 2.2vh;
}

.bonus-value-zero {
  color: #a4a3a1 !important;
}

.total-price-box {
  color: #1d1d1b;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  margin: 0;
  padding: 5vh 0 3vh;
  display: flex;
  justify-content: space-between;
}

.total-price-box.dark {
  color: #ededed;
}

.total-price-box span {
  font-size: 2.7vh;
}
@media screen and (min-width: 1024px) {
  .pay-method img {
    width: 3.111111111111111vh;
    height: 3.111111111111111vh;
    margin-right: 1.6666666666666667vh;
  }
  .pay-method p {
    font-size: 1.7777777777777777vh;
  }
  .selective-item img {
    width: 1.6666666666666667vh;
    height: 1.6666666666666667vh;
  }
}
</style>

<script>
export default {
  props: {
    value: String,
    total: Number,
    bonus_balance: Number,
    available_methods: Object,
    showBonus: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Boolean,
      default: true,
    },
    ignoreTheme: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      only_one_method: false,
    };
  },
  methods: {
    onUpdate(ev) {
      this.$emit("input", ev.target.value);
    },
  },
  computed: {
    currency() {
      return this.$store.state.currency.current;
    },
    currentIcon() {
      const themeSuffix = this.isDarkMode && !this.ignoreTheme ? "-white" : "";
      if (!this.validation) return `/icons/details/cash-red.svg`;
      if (this.value === null) return `/icons/details/cash${themeSuffix}.svg`;
      return `/icons/details/${this.value}${themeSuffix}.svg`;
    },
    isAuthorized() {
      return this.$store.getters['clients/isAuthorized'];
    }
  },
  mounted() {
    let count = 0;

    if (this.available_methods.cash) count++;
    if (this.available_methods.card) count++;
    if (this.available_methods.online) count++;
    if (this.showBonus) count++;

    if (count == 1) {
      this.only_one_method = true;
      if (this.available_methods.cash) {
        this.$emit("input", "cash");
      } else if (this.available_methods.card) {
        this.$emit("input", "card");
      } else {
        this.$emit("input", "online");
      }
    }
  },
};
</script>
