<template>
  <div class="yookassa-wrapper">
      <h2 class="yookassa-popup-info">
          <img src="/icons/yookassa.jpg" class="yookassa-icon">
            <a :href="widget.url" @click="$emit('close')" target="_blank">
                <LongButton variant="success" class="desktop-button">
                    {{ $t('online_payment_modal.redirect_btn') }}
                </LongButton>
            </a>
            <LongButton variant="danger"  class="desktop-button" @click="$emit('close')">
                    {{ $t('online_payment_modal.cancel_btn') }}
            </LongButton>
      </h2>
  </div>
</template>

<script>
import LongButton from "@/components/LongButton.vue"

export default {
    props: {
        widget: Object
    },
    mounted() {
        // document.getElementById('payuRedirectLink').click();
        // this.$emit('close');
    },
    components: {
        LongButton
    }
}
</script>

<style scoped>
.yookassa-wrapper {
    /* background: #908f8d; */
    opacity: 1;
    padding-top: 35vh;
}

.yookassa-popup-info {
    color: #ededed;
    font-size: 2vh;
    opacity: 1;
    text-align: center;
}

.yookassa-icon {
    width: 50vw;
}

@media screen and (min-width: 768px) {
    .yookassa-wrapper {
        padding-top: 25vh;
    }

    .yookassa-icon {
        width: 25vh;
    }

    .desktop-button {
        width: 45vh;
        font-size: 2.2vh;
    }
}
</style>