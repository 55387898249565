<template>
  <div class="language-modal-wrapper">
    <nav>
      <svg
        @click="$emit('close')"
        width="25"
        height="26"
        viewBox="0 0 25 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.488 25.3541C16.956 25.0669 17.1044 24.4517 16.8196 23.9799L10.1892 13L16.8196 2.02007C17.1044 1.54832 16.956 0.933102 16.488 0.645945C16.02 0.358789 15.4096 0.508438 15.1248 0.980192L8.18043 12.4801C7.98757 12.7994 7.98757 13.2006 8.18043 13.5199L15.1248 25.0198C15.4096 25.4916 16.02 25.6412 16.488 25.3541Z"
          fill="#ededed"
        />
      </svg>
      <span class="region-text" v-show="!searchActive">{{$t('marketplace.code_region')}}</span>
      <div
        :class="
          searchActive ? 'search-block right active' : 'search-block right'
        "
        v-thematic
      >
        <img src="../../assets/search.svg" alt class="search-icon" />
        <input
          @focus="searchActive = true"
          @blur="searchActive = false"
          type="text"
          :placeholder="searchActive ? $t('marketplace.list.search_ph') : ''"
          v-thematic
          class="search"
          maxlength="128"
          v-model="search"
        />
      </div>
    </nav>

    <div class="hide-content"></div>

    <div
      :class="
        isDarkMode
          ? 'language-modal-container dark'
          : 'language-modal-container'
      "
      ref="regionScroll"
    >
      <ul class="select-region" ref="listContainer" v-thematic>
        <li
          v-for="(item, index) in filteredCountryCodes"
          @click="onSelect(item)"
          :key="item.country_code + index"
          :class="{
            active: selected_region.country_code == item.country_code,
          }"
        >
          <img
            class="lan-img"
            :src="`/country-regions/flag_${item.flag_url}.png`"
          />
          <p>
            {{ item.country_code }} {{ item.dialling_code }}
            {{ item.country_english_name }}
          </p>
        </li>
      </ul>
      <button class="choose-lang" @click="onConfirm">OK</button>
    </div>
  </div>
</template>

<script>
import CountryCodesJSON from "@/country_codes.json";

export default {
  data() {
    return {
      selected_region: "+380",
      //  flag_name:[]
      searchActive: false,
      search: null,
    };
  },
  props: {
    initial_phone: String,
  },
  computed: {
    formattedCountryCodes() {
      return CountryCodesJSON.map((code) => {
        return {
          country_code: code.country_code,
          dialling_code: code.dialling_code,
          placeholder: code.national_number_example,
          country_native_name: code.country_native_name,
          mask: code.national_number_example
            .replace(/[()]/g, "")
            .replace(/[0-9]/g, "0")
            .trim(),
          country_english_name: code.country_english_name,
          flag_url: code.country_english_name.replace(/\s/g, "_").toLowerCase(),
        };
      });
    },
    filteredCountryCodes() {
      if (!this.search) return this.formattedCountryCodes;

      const q = this.search.toLowerCase();

      return this.formattedCountryCodes.filter(it => {
        return `${it.country_code};${it.dialling_code};${it.country_native_name};${it.country_english_name}`.toLowerCase().includes(q);
      });
    }
  },
  methods: {
    onSelect(item) {
      this.selected_region = item;
    },
    onConfirm() {
      this.$emit("select", this.selected_region);
      this.$emit("close");
    },
    checkInitialPhone() {
      if (this.initial_phone) {
        const ordered_list = this.formattedCountryCodes.slice().sort((a, b) => {
          return b.dialling_code.length - a.dialling_code.length;
        });

        this.selected_region = ordered_list.find((c) => {
          if (!this.initial_phone.startsWith(c.dialling_code)) return false;
          const raw_mask = c.mask.replace(/[\s()-]/g, "");

          return (
            this.initial_phone.length >= (c.dialling_code + raw_mask).length
          );
        });

        // console.log(this.selected_region);
      } else {
        this.selected_region = this.formattedCountryCodes.find(
          (c) => c.country_code == this.$store.state.worldwide.region_code
        );
      }

      this.$emit("select", this.selected_region);

      this.$nextTick(() => {
        setTimeout(() => {
          const index = this.formattedCountryCodes.indexOf(
            this.selected_region
          );

          console.log(index);

          if (index !== -1) {
            const single_phone_height =
              this.$refs.listContainer.getBoundingClientRect().height /
              this.formattedCountryCodes.length;
            console.log(single_phone_height);
            this.$refs.regionScroll.scrollTop =
              index * single_phone_height - single_phone_height * 2;
          }
        }, 100);
      });
    },
  },

  mounted() {
    this.checkInitialPhone();
  },
  
};
</script>

<style scoped>
.language-modal-container {
  background: #a4a3a1;
  display: flex;
  flex-flow: column nowrap;
  margin: 0 auto;
  transition: 0.3s;
  overflow-y: auto;
  z-index: 10;
  height: 100vh;
  position: absolute;
  left: 0;
  right: 0;
  top: 7vh;
  width: 100%;
}

nav {
  padding: 2.2vh 2.5vh;
  background: #1d1d1b;
  position: absolute;
  top: 0;
  width: 100%;
  height: 7.7vh;
  z-index: 11;
  margin-right: auto;
}

nav svg {
    display: block;
    margin-left: -0.8vh;
    height: 3.2vh;
    width: auto;
}

.region-text {
  color: #fff;
  font-size: 2.2vh;
  position: absolute;
  left: 6.5vh;
  top:2.2vh;
}

input.search {
  width: 100%;
  background: transparent;
  display: block;
  font-size: 2.3vh;
  padding: 0 0 0 1vh;
  border: none;
  outline: none;
  color: #1d1d1d;
}

.search-block {
  text-align: center;
  display: flex;
  position: absolute;
  top:1.5vh;
  border-radius: 1.2vh 0 0 1.2vh;
  background: transparent;
  border: 1px solid #908f8d;
  padding: 0.6vh 1.2vh;
  margin: 0;
  width: 8vh;
}

.search-block.right {
  transition: 0.5s;
  border: 1px solid #908f8d;
  right: -4.5vw;
}

.search-block.right.active {
  right: -3vh;
  width: 90vw;
  background: #ededed;
}

.language-modal-container.dark {
  background: #1d1d1b;
}

.choose-lang {
  width: 90%;
  position: fixed;
  bottom: 3.2vh;
  background: #129b81;
  text-align: center;
  font-size: 2vh;
  padding: 1.6vh 0;
  border-radius: 1.2vh;
  left: 0;
  right: 0;
  margin: 0 auto;
  border: none;
  outline: none;
  color: #ededed;
}

.lan-img {
  height: 4.5vh;
  width: 4.5vh;
  border-radius: 10vh;
  object-fit: cover;
  margin-right: 2vh;
}

.select-region {
  padding: 0 0 18vh 0;
  width: 100%;
  list-style-type: none;
  flex-flow: column;
  align-self: center;
  color: white;
  text-align: center;
  margin: 0 0 16.5vh;
}

.select-region.dark li {
  background: #464646;
  color: #ededed;
}

.select-region li:first-child {
  margin-top: 0;
}

.select-region li {
  display: flex;
  margin-top: 0.6vh;
  background: #ededed;
  align-items: center;
  padding: 2vh 2.5vh;

  color: #1d1d1d;
}

.select-region li.active {
  background: #129b81;
  color: #ededed;
}

.select-region li p {
  margin: 0;
  line-height: normal;
  font-size: 3vh;
  width: 100%;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.hide-content {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 4;
  overflow: hidden;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
  top: 0;
  left: 0;
}

.close {
  width: auto;
  height: 4.2vh;
  opacity: 1;
  right: 5.5vw;
  position: absolute;
}
</style>