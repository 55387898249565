<template>
  <div class="tinkoff-wrapper">
    <h2 class="tinkoff-popup-info">
      <img
        src="https://cdn.qrwaiter.com.ua/assets/integrations/tinkoff.png"
        class="tinkoff-icon"
      />
      <!-- <a :href="widget.url" @click="$emit('close')" target="_blank"> -->
      <a :href="widget.payment_url" @click="$emit('close')" target="_blank">
        <LongButton variant="success" type="submit" class="desktop-button">
          {{ $t("online_payment_modal.redirect_btn") }}
        </LongButton>
      </a>
      <!-- <a :href="`https://checkout.stripe.com/pay/${widget.session_id}?stripeAcct=`">
                test</a> -->
      <!-- </a> -->
      <LongButton variant="danger" @click="$emit('close')">
        {{ $t("online_payment_modal.cancel_btn") }}
      </LongButton>
    </h2>
  </div>
</template>

<script>
import LongButton from "@/components/LongButton.vue";

export default {
  props: {
    widget: Object,
  },
  mounted() {
    // document.getElementById('payuRedirectLink').click();
    // this.$emit('close');
  },
  methods: {
    openPayment() {},
    delayedClose() {
      setTimeout(() => {
        this.$emit("close");
      }, 1000);
    },
  },
  components: {
    LongButton,
  },
};
</script>

<style scoped>
.tinkoff-wrapper {
  /* background: #908f8d; */
  opacity: 1;
  padding-top: 35vh;
}

.tinkoff-popup-info {
  color: #ededed;
  font-size: 2vh;
  opacity: 1;
  text-align: center;
}

.tinkoff-icon {
  width: 50vw;
}
</style>