<template>
  <div>
    <div class="pending-modal" v-if="is_pending">
      <img src="/icons/loader.gif" class="panda" />
      <br />
      Зачекайте, ваш платіж в обробці...
    </div>
    <!-- <div class="wayforpay-wrapper"></div> -->

    <form ref="payForm" method="post" action="https://secure.wayforpay.com/pay" accept-charset="utf-8" v-show="false">
      <input 
        v-for="(v,k) in widget" 
        type="hidden"
        :key="k"
        :name="k" 
        :value="v">
      <input
        type="hidden" 
        name="returnUrl"
        :value="returnUrl">
    </form>
  </div>
</template>

<script>
export default {
  props: {
    widget: Object,
    order_id: String
  },
  data() {
    return {
      is_pending: false,
    };
  },
  methods: {
    run() {
      // const wayforpay = new Wayforpay();

      // wayforpay.run(
      //   this.widget,
      //   this.onApproved,
      //   this.onDeclined,
      //   this.onPending
      // );
      this.$nextTick(() => {
        this.$refs.payForm.submit();
        this.$emit('close');
      });
    },
    onApproved(response) {
      console.log(`Wayforpay onApproved`, response);
      this.is_pending = false;
      this.toggleGlobalScrolling(false);
      this.$emit("success");
    },
    onDeclined(response) {
      console.log(`Wayforpay onDeclined`, response);
      this.is_pending = false;
      this.$emit("close");
    },
    onPending(response) {
      console.log(`Wayforpay onPending`, response);
      this.is_pending = true;
      this.toggleGlobalScrolling(true);
    },
    onWindowMessage(msg) {
      if (msg.data == "WfpWidgetEventClose") {
        if (!this.is_pending) this.$emit("close");
      }
    },
  },
  mounted() {
    this.run();

    window.addEventListener("message", this.onWindowMessage, false);
  },
  computed: {
    returnUrl() {
      return 'https://' + window.location.hostname + '/api/payment/return_url?url=' + encodeURIComponent(`${window.location.href}?ret_order_id=${this.order_id}`);
    }
  }
};
</script>

<style scoped>
.wayforpay-wrapper {
}

.pending-modal {
  position: fixed;

  top: 45%;
  left: 0;

  opacity: 1;

  background: black;
  padding: 3vh 1vh;
  border-radius: 0.8vh;
  width: 90%;
  margin-left: 5%;

  color: #ededed;
  text-align: center;

  z-index: 501;
}

.panda {
  width: 3vh;
  margin-bottom: 3vh;
  height: auto;
}
</style>