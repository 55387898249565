<template>
  <div class="payment-modal-wrapper">
    <WayforpayModal 
      @close="onClose"
      @success="onSuccess" 
      :widget="widget" 
      :order_id="order_id"
      v-if="gateway =='wayforpay'" />

    <LiqpayModal 
      @close="onClose"
      @success="onSuccess" 
      :widget="widget" 
      v-if="gateway == 'liqpay'" />

    <StripeModal 
      @close="onClose"
      @success="onSuccess"
      :widget="widget"
      v-if="gateway == 'stripe'"/>
      
    <PayuModal 
      :widget="widget"
      @close="onClose"
      v-if="gateway == 'payu'"/>

    <YookassaModal 
      :widget="widget"
      @close="onClose"
      v-if="gateway == 'yookassa'"/>

    <TouchcardModal 
      :widget="widget"
      @close="onClose"
      v-if="gateway == 'touchcard'"/>

    <FondyModal 
      :widget="widget"
      @close="onClose"
      v-if="gateway == 'fondy'"/>

    <InterkassaModal 
      :widget="widget"
      @close="onClose"
      v-if="gateway == 'interkassa'"/>
    
    <TinkoffModal 
      :widget="widget"
      @close="onClose"
      v-if="gateway == 'tinkoff'"/>
  </div>
</template>

<script>
import LiqpayModal from "@/components/modals/LiqpayModal.vue";
import WayforpayModal from "@/components/modals/WayforpayModal.vue";
import StripeModal from "@/components/modals/StripeModal.vue"

import PayuModal from "@/components/modals/PayuModal.vue"

import YookassaModal from "@/components/modals/YookassaModal.vue"

import TouchcardModal from "@/components/modals/TouchcardModal.vue"

import FondyModal from "@/components/modals/FondyModal.vue"

import InterkassaModal from "@/components/modals/InterkassaModal.vue"

import TinkoffModal from "@/components/modals/TinkoffModal.vue"

import PaymentAPI from "@/api/payment";

export default {
  props: {
    order_id: String,
    mode: {
      type: String,
      default: "inside",
    },
    tips_amount: Number
  },
  data() {
    return {
      gateway: null,
      widget: null,
    };
  },
  methods: {
    fetchWidget() {
      let promise;

      if (this.mode == "inside") {
        promise = PaymentAPI.getInsideWidget(this.order_id);
      } else if (this.mode == 'preorder') {
        promise = PaymentAPI.getPreorderWidget(this.order_id);
      } else if (this.mode == 'hotels') {
        promise = PaymentAPI.getHotelsWidget(this.order_id);
      } else if (this.mode == 'reserve') {
        promise = PaymentAPI.getReserveWidget(this.order_id);
      } else if (this.mode == 'tips') {
        promise = PaymentAPI.getTipsWidget(
          this.order_id,
          this.tips_amount
        );
      }

      promise
        .then((data) => {
          this.gateway = data.gateway;
          this.widget = data.widget;
        })
        .catch((error) => {
          alert(`Помилка оплати: ${error}`);
          this.onClose();
        });
    },
    onClose() {
      this.$emit("close");
    },
    onSuccess() {
      this.$emit('success');
    }
  },
  mounted() {
    this.fetchWidget();

    this.toggleGlobalScrolling(true);

    const hide_target = window.document.getElementById("liqpayHideTarget");

    if (hide_target) hide_target.style.display = 'none';
  },
  beforeDestroy() {
    this.toggleGlobalScrolling(false);

    const hide_target = window.document.getElementById("liqpayHideTarget");

    if (hide_target) hide_target.style.display = 'block';
  },
  components: {
    WayforpayModal,
    LiqpayModal,
    StripeModal,
    PayuModal,
    YookassaModal,
    TouchcardModal,
    FondyModal,
    InterkassaModal,
    TinkoffModal
  },
};
</script>

<style scoped>
.payment-modal-wrapper {
  width: 100vw;
  height: 100vh;

  position: fixed;

  top: 0;
  left: 0;

  opacity: 0.9;
  backdrop-filter: blur(5px);

  background-color: #1d1d1b;

  z-index: 500;
}
</style>