<template>
  <div class="liqpay-checkout-container">
    <div id="liqpay_checkout"></div>
  </div>
</template>

<style scoped>
/* .liqpay-checkout-container {
  width: 100vw;
  height: 100vh;

  position: fixed;

  top: 0;
  left: 0;

  opacity: 0.9;
  backdrop-filter: blur(5px);

  background-color: #1d1d1b;

  z-index: 2500;
} */
</style>

<script>

export default {
  props: {
    widget: Object
  },
  data() {
    return {

    };
  },
  methods: {
    // fetchWidget() {
    //   PaymentAPI.getOrderWidget(this.order_id, this.mode)
    //     .then(data => {
    //       this.widgetData = data.widget;

    //       this.initWidget();
    //     })
    //     .catch(error => {
    //       alert(error);
    //     });
    // },
    initWidget() {
      const lp = LiqPayCheckout.init({
        data: this.widget.data,
        signature: this.widget.signature,
        embedTo: "#liqpay_checkout",
        language: "ru",
        mode: "popup" // embed || popup
      });

      const vm = this;

      lp.on("liqpay.callback", function(data) {
        if (data.status !== "success" && data.status != 'hold_wait') {
          vm.$emit("close");
        } else {
          vm.$emit("success");
        }
      });

      lp.on("liqpay.ready", function(data) {
        
      });

      lp.on("liqpay.close", function(data) {
        vm.$emit("close");
      });
    }
  },
  mounted() {
    this.initWidget();
  },
};
</script>