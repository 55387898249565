<template>
  <div class="stripe-wrapper">
      <h2 class="stripe-popup-info">
          <img src="/icons/stripe.svg" class="stripe-icon">
            <!-- <a :href="widget.url" @click="$emit('close')" target="_blank"> -->
                <form @submit="delayedClose" action="/api/stripe/redirect_to_checkout" target="_blank" method="POST">
                    <input type="hidden" name="public_key" :value="widget.public_key">
                    <input type="hidden" name="session_id" :value="widget.session_id">
                    
                    <LongButton variant="success" type="submit">
                        {{ $t('online_payment_modal.redirect_btn') }}
                    </LongButton>
                </form>
                <!-- <a :href="`https://checkout.stripe.com/pay/${widget.session_id}?stripeAcct=`">
                test</a> -->
            <!-- </a> -->
            <LongButton variant="danger" @click="$emit('close')">
                    {{ $t('online_payment_modal.cancel_btn') }}
            </LongButton>
      </h2>
  </div>
</template>

<script>
import LongButton from "@/components/LongButton.vue"

export default {
    props: {
        widget: Object
    },
    mounted() {
        // document.getElementById('payuRedirectLink').click();
        // this.$emit('close');
    },
    methods: {
        openPayment() {
            
        },
        delayedClose() {
            setTimeout(() => {
                this.$emit('close');
            }, 1000);
        }
    },
    components: {
        LongButton
    }
}
</script>

<style scoped>
.stripe-wrapper {
    /* background: #908f8d; */
    opacity: 1;
    padding-top: 35vh;
}

.stripe-popup-info {
    color: #ededed;
    font-size: 2vh;
    opacity: 1;
    text-align: center;
}

.stripe-icon {
    width: 50vw;
}
</style>